// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported} from 'firebase/messaging';
import axios from 'axios';

const firebaseConfig = {
    apiKey: "AIzaSyDp60mBbjUxDuzkr0t4-wt_OGY1Mond9sY",
    authDomain: "pwa-push-test-cf470.firebaseapp.com",
    projectId: "pwa-push-test-cf470",
    storageBucket: "pwa-push-test-cf470.appspot.com",
    messagingSenderId: "681511200192",
    appId: "1:681511200192:web:fe728c4485b9a7f50e1425"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    // The method getToken(): Promise<string> allows FCM to use the VAPID key credential
    // when sending message requests to different push services
    return getToken(messaging, { vapidKey: `BDx2uVKWGCxSSyUUWanpuGhYlSrf2qSISqwsFkcj4mn23nE4shxRgYj74j5sMLCaTjnPpBNLEaYi5T_qgWoEM2M` }) //to authorize send requests to supported web push services
        .then((currentToken) => {
            if (currentToken) {
                let url = '';
                if (window.location.hostname === 'localhost') {
                    url = `http://${window.location.hostname}`;
                } else {
                    url = `https://${window.location.hostname}`;
                }

                axios.post(`${url}:8000/api/setTokens`, ({ id: currentToken }))
                    .then(response => {
                        console.log('Response:', response?.data);
                        // Handle response data if needed
                    })
                    .catch(error => {
                        console.error('Error:', error?.response?.data);
                        // Handle error if needed.
                    });

                if (localStorage.getItem('fcmToken') && currentToken !== localStorage.getItem('fcmToken')) {
                    localStorage.setItem('fcmToken', currentToken);

                }

                else if (!localStorage.getItem('fcmToken')) {
                    localStorage.setItem('fcmToken', currentToken);

                }


            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });


