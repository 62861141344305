import logo from './logo.svg';
import './App.css';
import { ToastContainer, Zoom } from "react-toastify";
import Notification from "./firebaseNotifications/Notification";
import { getMessaging, isSupported } from 'firebase/messaging';
import { RRule } from 'rrule';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState } from 'react';

// Set up localizer
const localizer = momentLocalizer(moment);

// isSafariPushSupported();
function App() {

  function requestNotificationPermission() {
    if ('Notification' in window) {
      window.Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          // var notification = new Notification("Hi there!");
          console.log("Permission granted for Notification")
        }
      });
    }
  }
  const [events, setEvents] = useState([]);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name');
    if (title) {
      const newEvent = {
        title,
        start,
        end,
      };
      setEvents([...events, newEvent]);
    }
  };
  return (
    <div className="App">
      <div id="before-root">
        <button id="permissionButton" onClick={requestNotificationPermission}>
          Enable Notifications
        </button>
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
        closeButton={false}
      />
      <Notification />
      <div style={{ height: '600px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelect}
          views={['month', 'week', 'day']}
        />
      </div>
    </div>
  );
}

export default App;
